import './App.css';
import axios from 'axios';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CompShowInicio from './inicio/inicio.js';
import { useEffect, useState } from 'react';
import CompCreateProducto from './admin/createProducto';
import CompAdminProductos from './admin/showProdutosAdmin';
import CompEditProducto from './admin/EditProducto';
import CompLogin from './inicio/login.js';
import CompShowServicios from './inicio/servicios.js';




function App() {
  const [userRole, setUserRole] = useState(null)

  useEffect(() => {
    const loadUserRole = async () => {
      try {
        const token = localStorage.getItem('token'); // Asegúrate de almacenar el token cuando el usuario inicia sesión
        const res = await axios.get('https://www.seguridadtucuman.com/role/role/', {
          headers: {
            'x-auth-token': token,
          },
        });

        setUserRole(res.data.role);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Token expirado, borra el token del almacenamiento local
          localStorage.removeItem('token');
        } else {
          console.error('Error al cargar el rol del usuario:', error);
          setUserRole('none');
        }
      }
    }
    loadUserRole();
  }, []);


  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route exact path='/login' element={<CompLogin />} />

          <Route path='/' element={<CompShowInicio />} />
          <Route path='/servicios/:descripcion' element={<CompShowServicios />} />

          <Route path='/admin' element={<>  <CompAdminProductos />  </>} />
          <Route path='/admin/productos/edit/:id' element={<><CompEditProducto /> </>} />
          <Route path='/admin/createproducto' element={<> <CompCreateProducto /></>} />

        </Routes>
      </div>
    </BrowserRouter >
  );
}



export default App;
