import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
const CompShowFooter = () => {
    return (

        <footer className="flex md:flex-1 mt-44 md:mt-20 "  >
            <div id="website-footer" className="relative flex-1 z-10 break-word" style={{ color: 'rgb(255, 255, 255)', paddingTop: '0px', paddingBottom: '0px' }}>
                <div className="absolute inset-0 z-10 pointer-events-none">
                    <div className="absolute inset-0 z-10" style={{ backgroundImage: 'linear-gradient(rgb(15, 40, 56), rgb(10, 27, 48))' }}></div>
                </div>
                <div className="relative z-10 container mx-auto pt-16 lg:pt-32 pb-16 lg:pb-32">
                    <div className="flex flex-col lg:flex-row items-start lg:justify-between gap-12 ml-6">
                        <div className="flex flex-col gap-6 items-start lg:max-w-[30vw]">
                            <a className="grid max-w-full" target="_self" href="/es">
                                <h2 className="heading-small lg:heading-medium max-w-full whitespace-nowrap overflow-hidden overflow-ellipsis" style={{ color: 'rgb(255, 255, 255)', fontFamily: 'var(--header-logo-fontFamily)', fontWeight: 'var(--header-logo-fontWeight)', width: '374.04px' }}>Seguridad Perimetral Tucuman</h2>
                            </a>
                            <p className="leading-relaxed text-sm">Proporcionando soluciones avanzadas de seguridad para proteger lo que más importa.</p>
                            <div className="flex gap-4 mt-4">
                                <a href="https://wa.me/5493814474009?text=hola%20vengo%20de%20seguridaducuman.com" target='_blank'>  <FontAwesomeIcon icon={faWhatsapp} className='text-2xl cursor-pointer hover:text-3xl transition-all' /></a>
                                <a href='https://www.instagram.com/seguridad.perimetral.tucuman/' target='_blank'> <FontAwesomeIcon icon={faInstagram} className='text-2xl cursor-pointer hover:text-3xl transition-all' /></a>
                                <a href='https://www.facebook.com/profile.php?id=100063574538512#' target='_blank'> <FontAwesomeIcon icon={faFacebook} className='text-2xl cursor-pointer hover:text-3xl transition-all' /></a>

                            </div>
                        </div>
                        <div className="flex flex-col gap-12 lg:gap-6 lg:flex-1 lg:items-end">

                            <p className="body-normal lg:text-right whitespace-nowrap">
                                <span>Hecho por&nbsp;</span>
                                <a target="_blank" className="underline hover:text-gray-400 transition-colors" href="https://dicorus.com" style={{ color: 'rgb(255, 255, 255)' }}>Dicorus</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default CompShowFooter;
