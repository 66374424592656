import React, { useState, useEffect } from 'react';
import CompShowFooter from "./footer.js";
import CompShowServiciosSection from './serviciosSection';
import CompNosotros from './aboutUs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

const CompShowInicio = () => {



    return (
        <div className="flex flex-col h-full overflow-y-auto overflow-x-hidden smooth-scroll transition-all" style={{ backgroundColor: 'rgb(10, 27, 48)' }}>
            <header className='!z-[2000] bg-transparent blac'>
                <div className='grid items-center lg:gap-6 xl:gap-10 mx-auto pt-6 pb-6 px-6 lg:px-12' style={{ gridTemplateColumns: '1fr auto 1fr' }}>
                    <div className='lg:w-auto whitespace-nowrap'></div>
                    <div className='flex justify-center'>
                        <a className='grid max-w-full'>
                            <h2 className='heading-small lg:heading-medium max-w-full whitespace-nowrap overflow-hidden overflow-ellipsis md:text-2xl text-2xl text-white   '>Seguridad Perimetral Tucuman</h2>
                        </a>
                    </div>
                    <div className='flex justify-end'>
                        <div className='hidden lg:flex item-center gap-10'>
                            <ul className='lg:flex items-center gap-x-4 gap-y-2 flex-wrap justify-end hidden text-white'>
                                <a href="https://wa.me/5493814474009?text=hola%20vengo%20de%20seguridaducuman.com" target='_blank'>  <FontAwesomeIcon icon={faWhatsapp} className='text-2xl cursor-pointer hover:text-3xl transition-all' /></a>
                                <a href='https://www.instagram.com/seguridad.perimetral.tucuman/' target='_blank'> <FontAwesomeIcon icon={faInstagram} className='text-2xl cursor-pointer hover:text-3xl transition-all' /></a>
                                <a href='https://www.facebook.com/profile.php?id=100063574538512#' target='_blank'> <FontAwesomeIcon icon={faFacebook} className='text-2xl cursor-pointer hover:text-3xl transition-all' /></a>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <section className="relative">
                <div className='flex relative break-word items-center fix-safari-flickering' style={{
                    marginTop: '-80px',
                    paddingTop: '80px',
                    zIndex: 39,
                    minHeight: 'calc(0px + 100vh)', //FIXME: Puedo cambiar el 100 por un valor menor en caso de que quede espacio blancos
                    paddingBottom: '116px',
                    clipPath: 'url(#663adf493329284f02edccf8-pointDown)'
                }}
                >
                    <div className='w-full flex break-word transition-all duration-300 items-center'>

                        <div className='absolute inset-0 z-10 pointer-events-none'>
                            <div className='absolute inset-0 z-10' style={{
                                backgroundColor: 'rgb(15, 40, 56)',
                                opacity: 0.7
                            }}></div>
                            <div className='class="absolute inset-0 z-0 bg-white"'>
                                <span style={{
                                    boxSizing: 'border-box',
                                    display: 'block',
                                    overflow: 'hidden',
                                    width: 'initial',
                                    height: 'initial',
                                    background: 'none',
                                    opacity: 1,
                                    border: '0px',
                                    margin: '0px',
                                    padding: '0px',
                                    position: 'absolute',
                                    inset: '0px'
                                }}
                                >
                                    <img
                                        alt="background"
                                        src="/concertinaedited1.webp"
                                        decoding="async"
                                        data-nimg="fill"
                                        style={{
                                            position: 'absolute',
                                            inset: '0px',
                                            boxSizing: 'border-box',
                                            padding: '0px',
                                            border: 'none',
                                            margin: 'auto',
                                            display: 'block',
                                            width: '0px',
                                            height: '0px',
                                            minWidth: '100%',
                                            maxWidth: '100%',
                                            minHeight: '100%',
                                            maxHeight: '100%',
                                            objectFit: 'cover',
                                            objectPosition: '50% 50%'
                                        }}
                                    />


                                </span>
                            </div>
                        </div>

                        <div className='relative z-10 container mx-auto px-5 md:px-6 transition-all duration-300 pt-20 lg:pt-48 pb-20 lg:pb-48'>
                            <div className='relative z-10 w-full h-full'>
                                <div className='flex w-full gap-10 lg:gap-20 transition-all duration-300 flex-col lg:flex-row justify-start'>
                                    <div className='flex-1 flex flex-col w-full max-w-3xl items-start'>
                                        <h2 className='mb-6 break-word transition-all duration-300 text-left heading-xlarge text-white text-4xl lg:text-6xl' >Protege tu Hogar Ahora</h2>
                                        <p className='transition-all duration-300 body-large text-white text-xl' >Ofrecemos soluciones de seguridad perimetral efectivas y personalizadas para garantizar la protección absoluta de su hogar o negocio.</p>
                                        <div className='flex md:inline-flex flex-col md:flex-row md:gap-4 w-full md:w-max '>
                                            <a
                                                className="button xl w-full md:w-max mt-6 lg:mt-8 p-2 text-white text-center"
                                                target="_self"
                                                style={{
                                                    borderWidth: '2px',
                                                    borderStyle: 'solid',
                                                    boxShadow: 'none',
                                                    backgroundColor: 'transparent',
                                                    borderRadius: '8px',
                                                    borderColor: 'rgb(255, 255, 255)'
                                                }}
                                            >
                                                Contactanos
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg className="absolute bottom-0 left-0" width="1905" height="960" viewBox="0 0 1905 960">
                        <clipPath id="663adf493329284f02edccf8-pointDown">
                            <rect width="1905" height="844" fill="transparent"></rect>
                            <path d="M0 0L952.5 116L1905 0H0" transform="translate(0, 844)" fill="transparent"></path>
                        </clipPath>
                    </svg>
                </div>
            </section>
            <CompShowServiciosSection></CompShowServiciosSection>

            <CompNosotros></CompNosotros>


            {/* 
            <section className="relative">
                <div id="663add673329284f02edccd7" className="relative z-10 flex flex-none flex-shrink-0 break-word items-center" style={{ minHeight: 'calc(134px)', color: 'rgb(255, 255, 255)', zIndex: 36, marginTop: '-83px', paddingTop: '83px', paddingBottom: '0px' }}>
                    <div className="absolute inset-0 z-10 pointer-events-none">
                        <div className="absolute inset-0 z-10" style={{ backgroundColor: 'rgb(15, 40, 56)' }}></div>
                    </div>
                    <div className="relative z-10 container mx-auto pt-12 lg:pt-20 pb-12 lg:pb-20">
                        <div>
                            <h2 className="heading-large mb-10 transition-all duration-300 text-center text-6xl" style={{ color: '#FFFFFF' }}>Precios</h2>
                        </div>
                        <div className="flex flex-col items-center gap-6 lg:flex-row lg:items-stretch lg:gap-0 transition-all duration-300 lg:py-4 justify-center">
                            <span class="hidden align-start align-center align-end"></span>
                            <div className='relative flex w-full max-w-xl child-w-full transition-all duration-300'>
                                <div>
                                    <div className='flex flex-col gap-6 w-full h-full p-6 transition-all duration-300 border border-opacity-20 lg:!rounded-r-none rounded-sm md:rounded-md lg:rounded-lg'>

                                        <div className='flex flex-col gap-6'>

                                            <div className='flex-shrink-0 relative w-full mx-auto transition-all duration-300 aspect-w-16 aspect-h-9'>
                                                <span style={{
                                                    boxSizing: 'border-box',
                                                    display: 'block',
                                                    overflow: 'hidden',
                                                    width: 'initial',
                                                    height: 'initial',
                                                    background: 'none',
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                    right: 0
                                                }}
                                                >
                                                    <img
                                                        alt="man in yellow jacket and black pants wearing white mask"
                                                        title="man in yellow jacket and black pants wearing white mask"
                                                        src="/_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1611881290832-b65ee85fe04a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHwxNzB8fFNlY3VyaXR5fGVufDB8fHx8MTcxMDk3MjQyOHww%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1920&q=90"
                                                        decoding="async"
                                                        data-nimg="fill"
                                                        className="rounded-sm md:rounded-md lg:rounded-lg"
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            bottom: 0,
                                                            right: 0,
                                                            boxSizing: 'border-box',
                                                            padding: 0,
                                                            border: 'none',
                                                            margin: 'auto',
                                                            display: 'block',
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: '100%',
                                                            maxWidth: '100%',
                                                            minHeight: '100%',
                                                            maxHeight: '100%',
                                                            objectFit: 'cover',
                                                            objectPosition: 'center center'
                                                        }}
                                                        sizes="200vw"
                                                        srcSet="/_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1611881290832-b65ee85fe04a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHwxNzB8fFNlY3VyaXR5fGVufDB8fHx8MTcxMDk3MjQyOHww%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=480&q=90 480w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1611881290832-b65ee85fe04a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHwxNzB8fFNlY3VyaXR5fGVufDB8fHx8MTcxMDk3MjQyOHww%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=640&q=90 640w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1611881290832-b65ee85fe04a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHwxNzB8fFNlY3VyaXR5fGVufDB8fHx8MTcxMDk3MjQyOHww%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=768&q=90 768w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1611881290832-b65ee85fe04a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHwxNzB8fFNlY3VyaXR5fGVufDB8fHx8MTcxMDk3MjQyOHww%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1080&q=90 1080w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1611881290832-b65ee85fe04a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHwxNzB8fFNlY3VyaXR5fGVufDB8fHx8MTcxMDk3MjQyOHww%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1200&q=90 1200w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1611881290832-b65ee85fe04a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHwxNzB8fFNlY3VyaXR5fGVufDB8fHx8MTcxMDk3MjQyOHww%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1920&q=90 1920w"
                                                    />

                                                </span>
                                            </div>
                                            <div class="space-y-2"><p class="heading-small transition-all duration-300" >Monitoreo de seguridad</p></div>
                                            <div><p class="heading-large" >$2500</p><p class="body-normal">por mes</p></div>
                                            <a
                                                className="button lg"
                                                target="_self"
                                                style={{
                                                    borderWidth: '2px',
                                                    borderStyle: 'solid',
                                                    boxShadow: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: 'rgb(255, 255, 255)',
                                                    borderRadius: '8px',
                                                    borderColor: 'rgb(255, 255, 255)'
                                                }}
                                                href="/es?pt=NjYzYWRkM2FkZTNkNWVhYWE1ZjQxMTI0OjE3MTUyOTY5NTkuMjg3OnByZXZpZXc%3D#"
                                            >
                                                Más información
                                            </a>
                                            <div class="body-normal transition-all duration-300" >Servicio de monitoreo y supervisión de seguridad las 24 horas del día.</div>
                                            <div className="flex flex-col gap-3">
                                                <div className="flex items-center gap-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" className="flex-shrink-0 w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m5 13 4 4L19 7"></path>
                                                    </svg>
                                                    <p className="body-normal">Monitoreo</p>
                                                </div>
                                                <div className="flex items-center gap-3" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" className="flex-shrink-0 w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m5 13 4 4L19 7"></path>
                                                    </svg>
                                                    <p className="body-normal">Seguridad 24/7</p>
                                                </div>
                                                <div className="flex items-center gap-3" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" className="flex-shrink-0 w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m5 13 4 4L19 7"></path>
                                                    </svg>
                                                    <p className="body-normal">Supervisión</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="relative flex w-full max-w-xl child-w-full transition-all duration-300 lg:-my-4 lg:!-ml-0.5">
                                <div>
                                    <div className="flex flex-col gap-6 w-full h-full p-6 transition-all duration-300 border-2 border-opacity-40 lg:py-10 rounded-sm md:rounded-md lg:rounded-lg" style={{ borderColor: '#FFFFFF' }}>
                                        <div className="flex flex-col gap-6" style={{ color: '#FFFFFF' }}>
                                            <div className="flex-shrink-0 relative w-full mx-auto transition-all duration-300 aspect-w-16 aspect-h-9">
                                                <span style={{ boxSizing: 'border-box', display: 'block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                                                    <img
                                                        alt="man in black suit wearing black sunglasses"
                                                        title="man in black suit wearing black sunglasses"
                                                        src="/_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1618371731836-2b9bff9ac72a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw0M3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1920&q=90"
                                                        decoding="async"
                                                        data-nimg="fill"
                                                        className="rounded-sm md:rounded-md lg:rounded-lg"
                                                        style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                                                        sizes="200vw"
                                                        srcSet="/_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1618371731836-2b9bff9ac72a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw0M3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=480&q=90 480w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1618371731836-2b9bff9ac72a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw0M3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=640&q=90 640w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1618371731836-2b9bff9ac72a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw0M3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=768&q=90 768w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1618371731836-2b9bff9ac72a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw0M3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1080&q=90 1080w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1618371731836-2b9bff9ac72a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw0M3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1200&q=90 1200w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1618371731836-2b9bff9ac72a%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw0M3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1920&q=90 1920w"
                                                    />
                                                </span>
                                            </div>
                                            <div className="space-y-2">
                                                <div className="flex-shrink-0 inline-block min-w-20 px-3 py-1 body-small font-semibold text-center rounded-3xl" style={{ backgroundColor: '#FFFFFF', color: '#111827' }}>Popular</div>
                                                <p className="heading-small transition-all duration-300" >Instalación de sistemas</p>
                                            </div>
                                            <div>
                                                <p className="heading-large" >$4000</p>
                                                <p className="body-normal" >por visita</p>
                                            </div>
                                            <a className="button lg" target="_self" style={{ borderWidth: '2px', borderStyle: 'solid', boxShadow: 'none', fontFamily: 'var(--body-fontFamily)', fontWeight: 'var(--body-fontWeight)', fontStyle: 'var(--body-fontStyle)', backgroundColor: 'transparent', color: 'rgb(255, 255, 255)', borderRadius: '8px', borderColor: 'rgb(255, 255, 255)' }} href="/es?pt=NjYzYWRkM2FkZTNkNWVhYWE1ZjQxMTI0OjE3MTUyOTY5NTkuMjg3OnByZXZpZXc%3D#">Más información</a>
                                            <div className="body-normal transition-all duration-300" >Servicio profesional para la instalación de sistemas de seguridad.</div>
                                            <div className="flex flex-col gap-3">
                                                <div className="flex items-center gap-3" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" className="flex-shrink-0 w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m5 13 4 4L19 7"></path>
                                                    </svg>
                                                    <p className="body-normal">Profesional</p>
                                                </div>
                                                <div className="flex items-center gap-3" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" className="flex-shrink-0 w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m5 13 4 4L19 7"></path>
                                                    </svg>
                                                    <p className="body-normal">Instalación</p>
                                                </div>
                                                <div className="flex items-center gap-3" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" className="flex-shrink-0 w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m5 13 4 4L19 7"></path>
                                                    </svg>
                                                    <p className="body-normal">Sistemas</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative flex w-full max-w-xl child-w-full transition-all duration-300">
                                <div>
                                    <div className="flex flex-col gap-6 w-full h-full p-6 transition-all duration-300 border border-opacity-20 lg:!rounded-l-none lg:!border-l-0 rounded-sm md:rounded-md lg:rounded-lg" style={{ borderColor: '#FFFFFF' }}>
                                        <div className="flex flex-col gap-6" style={{ color: '#FFFFFF' }}>
                                            <div className="flex-shrink-0 relative w-full mx-auto transition-all duration-300 aspect-w-16 aspect-h-9">
                                                <span style={{ boxSizing: 'border-box', display: 'block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                                                    <img
                                                        alt="man in black police uniform"
                                                        title="man in black police uniform"
                                                        src="/_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1517913451214-e22ce660e086%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw1N3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1920&q=90"
                                                        decoding="async"
                                                        data-nimg="fill"
                                                        className="rounded-sm md:rounded-md lg:rounded-lg"
                                                        style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                                                        sizes="200vw"
                                                        srcSet="/_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1517913451214-e22ce660e086%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw1N3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=480&q=90 480w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1517913451214-e22ce660e086%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw1N3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=640&q=90 640w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1517913451214-e22ce660e086%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw1N3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=768&q=90 768w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1517913451214-e22ce660e086%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw1N3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1080&q=90 1080w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1517913451214-e22ce660e086%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw1N3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1200&q=90 1200w, /_next/image?url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1517913451214-e22ce660e086%3Fcrop%3Dentropy%26cs%3Dtinysrgb%26fit%3Dmax%26fm%3Djpg%26ixid%3DM3wyNjI5NjF8MHwxfHNlYXJjaHw1N3x8U2VjdXJpdHl8ZW58MHx8fHwxNzEwOTcyMzQxfDA%26ixlib%3Drb-4.0.3%26q%3D80%26w%3D1080&w=1920&q=90 1920w"
                                                    />
                                                </span>
                                            </div>
                                            <div className="space-y-2">
                                                <p className="heading-small transition-all duration-300" >Vigilancia en eventos</p>
                                            </div>
                                            <div>
                                                <p className="heading-large" >$1500</p>
                                                <p className="body-normal" >por evento</p>
                                            </div>
                                            <a className="button lg" target="_self" style={{ borderWidth: '2px', borderStyle: 'solid', boxShadow: 'none', fontFamily: 'var(--body-fontFamily)', fontWeight: 'var(--body-fontWeight)', fontStyle: 'var(--body-fontStyle)', backgroundColor: 'transparent', color: 'rgb(255, 255, 255)', borderRadius: '8px', borderColor: 'rgb(255, 255, 255)' }} href="/es?pt=NjYzYWRkM2FkZTNkNWVhYWE1ZjQxMTI0OjE3MTUyOTY5NTkuMjg3OnByZXZpZXc%3D#">Más información</a>
                                            <div className="body-normal transition-all duration-300" >Servicio de vigilancia y control de seguridad durante eventos corporativos.</div>
                                            <div className="flex flex-col gap-3">
                                                <div className="flex items-center gap-3" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" className="flex-shrink-0 w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m5 13 4 4L19 7"></path>
                                                    </svg>
                                                    <p className="body-normal">Vigilancia</p>
                                                </div>
                                                <div className="flex items-center gap-3" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" className="flex-shrink-0 w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m5 13 4 4L19 7"></path>
                                                    </svg>
                                                    <p className="body-normal">Eventos</p>
                                                </div>
                                                <div className="flex items-center gap-3" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" className="flex-shrink-0 w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m5 13 4 4L19 7"></path>
                                                    </svg>
                                                    <p className="body-normal">Seguridad</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </section>
 */}

            <section className="relative mt-36" >
                <div id="663add733329284f02edccda" className="relative flex break-word !min-h-80vh md:!min-h-50vh" style={{ zIndex: 33, paddingTop: '0px', paddingBottom: '0px' }}>
                    <div className="absolute inset-0 w-full">
                        <div className="!absolute inset-0 z-5 leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom" id="map-1715391351174-480" tabIndex="0">
                            <iframe width='100%' height='400px' src="https://api.mapbox.com/styles/v1/dicorus/clw1gz2gu08az01ph59c56j05.html?title=false&access_token=pk.eyJ1IjoiZGljb3J1cyIsImEiOiJjbHcxZ2ZtOGswNWl3MmlvMGIyYnEzczlrIn0.4R-1dS1WNVwHGEpPYoP-5g&zoomwheel=false#13/-26.816/-65.301" title="Monochrome"></iframe>

                        </div>
                    </div>
                    <div className="relative z-10 container mx-auto py-12 lg:py-14 xl:py-20">
                        <div className="flex   w-full md:flex-row"> {/* Cambiado a flex-row */}
                            <div className="relative z-10   md:w-1/2 xl:w-2/5 flex flex-col gap-6 p-6 lg:p-10 shadow overflow-hidden rounded-sm md:rounded-md lg:rounded-lg">
                                <div className=" absolute inset-0 z-10 pointer-events-none">
                                    <div className="absolute inset-0 z-10" style={{ backgroundColor: 'rgb(10, 27, 48)' }}></div>
                                </div>
                                <div className="relative z-10  flex flex-col gap-4">
                                    <div>
                                        <h2 className="heading-medium mb-2 text-3xl md:text-4xl" style={{ color: 'rgb(255, 255, 255)' }}>Ubicación</h2>
                                        <pre className="body-normal" style={{ color: 'rgb(255, 255, 255)' }}>Yerba Buena, Tucumán </pre>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
           <CompShowFooter></CompShowFooter>

        </div>
    );
};

export default CompShowInicio;
