import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { motion } from "framer-motion"

const CompShowServiciosSection = () => {



    return (

        <section className='relative'>
            <div id="663add5f3329284f02edccd4" className="relative flex flex-none flex-shrink-0 break-word items-center -mt-36 p-12 md:p-0" style={{ minHeight: 'calc(134px)', zIndex: 38, paddingTop: '117px', paddingBottom: '0px' }}>
                <div className="absolute inset-0 z-10 pointer-events-none">
                    <div className="absolute inset-0 z-10" style={{ backgroundColor: 'rgb(10, 27, 48)' }}></div>
                </div>
                <div className="relative z-10 container mx-auto pt-16 lg:pt-32 pb-16 lg:pb-32">
                    <div>
                        <div>
                            <h2 className="heading-large mb-6 text-left text-5xl " style={{ color: '#FFFFFF' }}>Nuestros servicios</h2>
                        </div>
                        <div className="flex flex-col sm:flex-row relative justify-start gap-10 py-12">
                            <div className="group w-full relative overflow-hidden rounded-sm md:rounded-md lg:rounded-lg" style={{ flex: '0 0 calc(33.3333% - 26.6667px)' }}>
                                <Link to={"/servicios/All"} >
                                    <div>

                                        <div className="flex-shrink-0 relative overflow-hidden aspect-w-1 aspect-h-1 rounded-sm md:rounded-md lg:rounded-lg" style={{ minHeight: '228px' }}>
                                            <span className="relative block" style={{ height: '45rem' }}></span>
                                            <span style={{ boxSizing: 'border-box', display: 'block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                                                <img
                                                    alt="Concertina"
                                                    src="/4.jpg"
                                                    decoding="async"
                                                    data-nimg="fill"
                                                    className=""
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        boxSizing: 'border-box',
                                                        padding: 0,
                                                        border: 'none',
                                                        margin: 'auto',
                                                        display: 'block',
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        minHeight: '100%',
                                                        maxHeight: '100%',
                                                        objectFit: 'cover',
                                                        objectPosition: '75% 5%'
                                                    }}
                                                    sizes="200vw"
                                                />
                                            </span>
                                        </div>
                                        <div className="absolute w-full bottom-0 left-0 flex flex-col justify-end" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', minHeight: '228px' }}>
                                            <div className="flex flex-col text-left items-start p-6 justify-end" style={{ color: '#FFFFFF' }}>
                                                <p className="heading-medium mb-4 text-4xl" >Concertina</p>
                                                <div className="rich-text-block text-lg"  >
                                                    <p>¡Protege tu propiedad con nuestra concertina! Una solución de seguridad perimetral confiable y efectiva. Sus cuchillas afiladas y resistente diseño son la mejor barrera contra intrusos no deseados.</p>
                                                </div>
                                                <motion.div
                                                    initial={{ opacity: 0, scale: 0.5 }} // Iniciar el botón oculto y en escala reducida
                                                    whileHover={{ opacity: 1, scale: 1 }} // Mostrar el botón y aumentar su escala al hacer hover
                                                    className="absolute inset-0 flex justify-center items-center"
                                                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
                                                >
                                                    <Link to="/servicios/All" className="bg-transparent border-2 border-white text-white py-2 px-4 rounded">
                                                        Ver más
                                                    </Link>
                                                </motion.div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>


                            <div className="group w-full relative overflow-hidden rounded-sm md:rounded-md lg:rounded-lg" style={{ flex: '0 0 calc(33.3333% - 26.6667px)' }}>
                                <Link to={"/servicios/All"} >
                                    <div>
                                        <div className="flex-shrink-0 relative overflow-hidden aspect-w-1 aspect-h-1 rounded-sm md:rounded-md lg:rounded-lg" style={{ minHeight: '228px' }}>
                                            <span className="relative block" style={{ height: '45rem' }}></span>
                                            <span style={{ boxSizing: 'border-box', display: 'block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                                                <img
                                                    alt="Monitoreo en tiempo real"
                                                    src="/5.jpg"
                                                    decoding="async"
                                                    data-nimg="fill"
                                                    className=""
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        boxSizing: 'border-box',
                                                        padding: 0,
                                                        border: 'none',
                                                        margin: 'auto',
                                                        display: 'block',
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        minHeight: '100%',
                                                        maxHeight: '100%',
                                                        objectFit: 'cover',
                                                        objectPosition: '80% 5%'
                                                    }}
                                                    sizes="200vw"
                                                />
                                            </span>
                                            <div className="absolute w-full bottom-0 left-0 flex flex-col justify-end" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', minHeight: '228px' }}>
                                                <div className="flex flex-col text-left items-start p-6 justify-end" style={{ color: '#FFFFFF' }}>
                                                    <p className="heading-medium mb-4 text-4xl" >Cerco Eléctrico</p>
                                                    <div className="rich-text-block text-lg"  >
                                                        <p>Refuerza la seguridad de tu propiedad con nuestro cerco eléctrico. Una barrera avanzada que protege con descargas eléctricas disuasorias. ¡Contáctanos y protege tu hogar hoy!</p>
                                                    </div>
                                                    <motion.div
                                                    initial={{ opacity: 0, scale: 0.5 }} // Iniciar el botón oculto y en escala reducida
                                                    whileHover={{ opacity: 1, scale: 1 }} // Mostrar el botón y aumentar su escala al hacer hover
                                                    className="absolute inset-0 flex justify-center items-center"
                                                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
                                                >
                                                    <Link to="/servicios/All" className="bg-transparent border-2 border-white text-white py-2 px-4 rounded">
                                                        Ver más
                                                    </Link>
                                                </motion.div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="group w-full relative overflow-hidden rounded-sm md:rounded-md lg:rounded-lg" style={{ flex: '0 0 calc(33.3333% - 26.6667px)' }}>
                                <Link to={"/servicios/All"} >

                                    <div>
                                        <div className="flex-shrink-0 relative overflow-hidden aspect-w-1 aspect-h-1 rounded-sm md:rounded-md lg:rounded-lg" style={{ minHeight: '228px' }}>
                                            <span className="relative block" style={{ height: '45rem' }}></span>
                                            <span style={{ boxSizing: 'border-box', display: 'block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                                                <img
                                                    alt="Monitoreo en tiempo real"
                                                    src="/3.jpg"
                                                    decoding="async"
                                                    data-nimg="fill"
                                                    className=""
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        boxSizing: 'border-box',
                                                        padding: 0,
                                                        border: 'none',
                                                        margin: 'auto',
                                                        display: 'block',
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: '100%',
                                                        maxWidth: '100%',
                                                        minHeight: '100%',
                                                        maxHeight: '100%',
                                                        objectFit: 'cover',
                                                        objectPosition: '38% 5%'
                                                    }}
                                                    sizes="200vw"
                                                />
                                            </span>
                                            <div className="absolute w-full bottom-0 left-0 flex flex-col justify-end" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', minHeight: '228px' }}>
                                                <div className="flex flex-col text-left items-start p-6 justify-end" style={{ color: '#FFFFFF' }}>
                                                    <p className="heading-medium mb-4 text-4xl" >Pinches de Seguridad</p>
                                                    <div className="rich-text-block text-lg"  >
                                                        <p>¡Asegura tu propiedad con nuestros pinchos de seguridad! Una solución efectiva para disuadir a intrusos no deseados. Su diseño resistente y punzante ofrece una barrera confiable.</p>
                                                    </div>
                                                        <motion.div
                                                    initial={{ opacity: 0, scale: 0.5 }} // Iniciar el botón oculto y en escala reducida
                                                    whileHover={{ opacity: 1, scale: 1 }} // Mostrar el botón y aumentar su escala al hacer hover
                                                    className="absolute inset-0 flex justify-center items-center"
                                                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
                                                >
                                                    <Link to="/servicios/All" className="bg-transparent border-2 border-white text-white py-2 px-4 rounded">
                                                        Ver más
                                                    </Link>
                                                </motion.div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    );
};

export default CompShowServiciosSection;
