import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import CompShowFooter from './footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'


const CompShowServicios = () => {
    const [productos, setProductos] = useState([]);
    const { descripcion } = useParams(); // Obtener el parámetro de la URL

    useEffect(() => {
        const fetchProductos = async () => {
            try {
                const response = await axios.get(`https://www.seguridadtucuman.com/productos/productos/All`);
                setProductos(response.data);
            } catch (error) {
                console.error('Error al obtener los productos:', error);
            }
        };
        console.log(productos);

        fetchProductos();
    }, [descripcion]);

    return (
        <div className="bg-[#0A1B30] min-h-screen">
            <div className="p-4">
                <h1 className="text-center text-white text-4xl p-8">Nuestros Servicios y Productos</h1>
                <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10">
                    {productos.map((producto) => (
                        <div key={producto.id} className="bg-[#1B2A40] rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
                            <img
                                alt={producto.descripcion}
                                src={`https://www.seguridadtucuman.com/${producto.img_productos[0].ruta}`}
                                className="w-full h-64 object-cover"
                            />
                            <div className="p-4">
                                <p className="text-white text-lg mb-2">{producto.descripcion}</p>
                                <div className="text-white">
                                    <p className="text-sm">{producto.detalles}</p>
                                    <p className="mt-2 font-semibold text-lg">${producto.precio}</p>
                                    <a
                                        href={`https://wa.me/5493814474009?text=Hola,%20vengo%20de%20seguridaducuman.com.%20Estoy%20interesado%20en:%20${encodeURIComponent(producto.descripcion)}%20-%20${encodeURIComponent(producto.detalles)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center mt-4 text-green-500 hover:text-green-600 transition-all"
                                    >
                                        <span className="mr-2">Obtener más información</span>
                                        <FontAwesomeIcon icon={faWhatsapp} className="text-2xl" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <CompShowFooter />
        </div>



    );
};

export default CompShowServicios;
