import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const CompNosotros = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.1, // Ajusta este valor según tus necesidades
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className={`relative bg-[#0A1B30] text-white py-16 mt-28 transition-opacity duration-1000 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div className="container mx-auto flex flex-col lg:flex-row-reverse items-center gap-10 lg:gap-20 px-6">
        <div className="flex-1 flex flex-col items-start max-w-xl">
          <h2 className="text-4xl lg:text-6xl font-bold mb-4">Sobre Nosotros</h2>
          <p className="mb-4 leading-relaxed">
            En Seguridad Perimetral Tucumán, nos especializamos en ofrecer soluciones avanzadas de seguridad.
          </p>
          <p className="mb-6 leading-relaxed">
            Nos comprometemos a proporcionar tranquilidad y protección a nuestros clientes con productos de alta calidad y tecnología de vanguardia.
          </p>
          <Link to={'/servicios/All'} className="inline-block py-3 px-6 border-2 border-white rounded-lg hover:bg-white hover:text-gray-900 transition-colors shadow-lg">
            Ver más
          </Link>
        </div>
        <div className="flex-1 flex justify-center lg:justify-start">
          <svg width="250" height="200" viewBox="0 0 163 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame 3" filter="url(#filter0_d_14_20)">
              <path id="Vector 4" d="M46 1L81.5 61L117 1H46Z" fill="#002652" stroke="#002652" />
              <path id="Vector 5" d="M41.5 23H5.99999C1.19999 50.6 22.3333 74.1667 33.5 82.5L52 50C45.2 43.2 42.1667 29.1667 41.5 23Z" fill="#8B8989" stroke="#8B8989" />
              <path id="Vector 6" d="M121.5 23H157C161.8 50.6 140.667 74.1667 129.5 82.5L111 50C117.8 43.2 120.833 29.1667 121.5 23Z" fill="#8B8989" stroke="#8B8989" />
              <path id="Vector 3" d="M23.5 98.5L63.5 31.5L81.5 61L100 31.5L139.5 98.5H97L81.5 73C76.6667 81.5 66.8 98.5 66 98.5H23.5Z" fill="#F93922" stroke="#F93922" />
            </g>
            <defs>
              <filter id="filter0_d_14_20" x="0.801941" y="0.5" width="161.396" height="106.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14_20" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14_20" result="shape" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  );
};

export default CompNosotros;
